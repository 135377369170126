import React, { ComponentProps } from 'react'
import styled from 'styled-components'

export type NavItemProps = ComponentProps<typeof StyledNavItem> & {
  title: React.ReactNode
  focus?: boolean
  href?: string
}

// Change to theme color with: 
export const StyledNavItem = styled.div<{ $focus?: boolean; show?: boolean }>`
  display: inline-block;
  opacity: ${props => props.show ? 1 : 0};
  padding-left: ${props => props.theme.typography.pxToRem(10)};
  padding-right: ${props => props.theme.typography.pxToRem(10)};
  will-change: opacity;
  transition: opacity 0.15s ease-in-out;

  &::after {
    content: '';
    width: ${props => props.$focus ? props.theme.typography.pxToRem(25) : '0'};
    height: ${props => props.theme.typography.pxToRem(5)};
    opacity: ${props => props.$focus ? 1 : 0.25};
    transform: translateY(20px);
    display: block;
    background: ${props => props.theme.palette.primary.main};
    transition: 300ms;
  }

  &:hover {
    cursor: pointer;
  }

  &:hover::after {
    opacity: 1;
    width: 100%;
  }

  &:focus::after {
    opacity: 1;
    width: 100%;
  }

  ${props => props.theme.breakpoints.up('lg')} {
    padding-left: ${props => props.theme.typography.pxToRem(30)};
    padding-right: ${props => props.theme.typography.pxToRem(30)};
  }
`

export const StyledNavItemText = styled.a`
  color: ${props => props.theme.palette.text.primary};
  font-weight: 500;
  font-size: ${props => props.theme.typography.pxToRem(18)};
  text-decoration: none;

  &:hover {
    color: ${props => props.theme.palette.text.primary};
  }
`

const focusIn = () => {
  const header = document.querySelector('header[class*="StyledNavContainers__StyledNavigationContainer"]')

  if (header) {
    (header as HTMLElement).style.backgroundColor = 'rgba(255, 255, 255, 0.9)'
  }
}

const focusOut = () => {
  const header = document.querySelector('header[class*="StyledNavContainers__StyledNavigationContainer"]')

  if (header) {
    (header as HTMLElement).style.backgroundColor = 'transparent'
  }
}

export const NavItem: React.FC<NavItemProps> = ({ title, children, href, focus=false }) => {
  return (
    <StyledNavItem $focus={focus} show={true} tabIndex={0} onFocus={focusIn} onBlur={focusOut}>
      <StyledNavItemText href={href} >{ title }</StyledNavItemText>
      { children }
    </StyledNavItem>
  )
}